<template>
  <div>
    <h2 v-html="$t('search_card_message_sent_title')"></h2>
    <v-text-field
      id="search_card_message_sent_messageTitle"
      v-model="SearchMessageSentStore.messageTitle"
      :label="$t('search_card_message_sent_messageTitle')"
      type="text"
      @keyup.enter="search"
    />
    <v-text-field
      id="search_card_message_sent_recipientFullName"
      v-model="SearchMessageSentStore.recipientFullName"
      :label="$t('search_card_message_sent_recipientFullName')"
      type="text"
      @keyup.enter="search"
    />
    <DbmDatePicker
      id="search_card_message_sent_creationDateFrom"
      v-model="SearchMessageSentStore.creationDateFrom"
      :dateLabel="$t('search_card_message_sent_creationDateFrom')"
      type="date"
      @keyup.enter="search"
      :clearable="true"
    />
    <DbmDatePicker
      id="search_card_message_sent_creationDateTo"
      v-model="SearchMessageSentStore.creationDateTo"
      :dateLabel="$t('search_card_message_sent_creationDateTo')"
      type="date"
      @keyup.enter="search"
      :clearable="true"
    />
    <v-select
      :items="sendingChannels"
      item-value="name"
      item-title="name"
      v-model="SearchMessageSentStore.sendingChannel"
      :clearable="true"
      :label="$t('search_card_message_sent_channel')"
      @keyup.enter="search"
    ></v-select>
    <v-select
      id="search_card_message_sent_status_read"
      :label="$t('search_card_message_sent_status_read')"
      v-model="SearchMessageSentStore.read"
      :items="optionsBooleanRead"
      item-title="text"
      item-value="status"
      clearable
    />
    <v-select
      id="search_card_message_sent_status"
      :label="$t('search_card_message_sent_status')"
      v-model="SearchMessageSentStore.deliveryStatus"
      :items="optionsDeliveryStatus"
      item-title="text"
      item-value="status"
      clearable
    />
    <v-select
      id="search_card_message_sent_attachments"
      :label="$t('search_card_message_sent_attachments')"
      v-model="SearchMessageSentStore.attachments"
      :items="optionsBooleanAttachments"
      item-title="text"
      item-value="status"
      clearable
    />
    <v-text-field
      id="search_card_message_sent_tags"
      v-model="SearchMessageSentStore.tags"
      :label="$t('search_card_message_sent_tags')"
      type="text"
      @keyup.enter="search"
    />
    <v-switch
      id="search_card_message_sent_visualization_reduced"
      v-model="SearchMessageSentStore.reduced"
      color="primary"
      :label="$t('search_card_message_sent_visualization_reduced')"
      @keyup.enter="search"
    ></v-switch>
  </div>
</template>

<script>
import baseSearchVue from './baseSearch.vue'
import { useMessageTypesStore } from '@/store/enums/MessageTypesStore'
import SearchMessageSentStore from '@/store/SearchMessageSentStore'
import DbmDatePicker from '@/components/dbmDatePicker.vue'

export default {
  name: 'searchMessageSent',
  components: { DbmDatePicker },
  extends: baseSearchVue,
  data() {
    return {
      loadingSender: false,
      optionsBooleanRead: [
        { id: 0, status: true, text: this.$t('search_card_message_sent_status_read_true') },
        { id: 1, status: false, text: this.$t('search_card_message_sent_status_read_false') }
      ],
      optionsBooleanAttachments: [
        { id: 0, status: true, text: this.$t('search_card_message_sent_attachments_true') },
        { id: 1, status: false, text: this.$t('search_card_message_sent_attachments_false') }
      ],
      optionsDeliveryStatus: [
        { id: 0, status: 'INTERNAL', text: this.$t('search_card_message_status_internal') },
        { id: 1, status: 'IN_PREPARATION', text: this.$t('search_card_message_status_in_preparation') },
        { id: 2, status: 'TO_SEND', text: this.$t('search_card_message_status_to_send') },
        { id: 3, status: 'SENT', text: this.$t('search_card_message_status_sent') },
        { id: 4, status: 'DELIVERED', text: this.$t('search_card_message_status_delivered') },
        { id: 5, status: 'ERROR', text: this.$t('search_card_message_status_error') }
      ],
      sendingChannels: ['SMS', 'EMAIL', 'POST', 'INTERNAL']
    }
  },
  computed: {
    SearchMessageSentStore() {
      return this.$store.state.SearchMessageSentStore
    },
    messageTypes() {
      return useMessageTypesStore().messageTypes
    }
  },
  methods: {
    search() {
      this.$emit('search')
    },
    reset() {
      this.resetFields(this.SearchMessageSentStore)
    }
  },
  beforeMount() {
    useMessageTypesStore().fetchMessageTypes
  },
  mounted() {
    this.initSearchStoreFromQuery(this.SearchMessageSentStore, this.$route.query)
  }
}
</script>
